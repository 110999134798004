

import { createSignal, Show, } from "solid-js";
import { createStore, } from "solid-js/store";
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { MenuIcon } from "../icons"
import { ShowHeading } from '../SmallComponents/SmallHeader'
import { ContractPeriod } from '../MediumComponents/MediumContractPeriod'
import { ContractRates } from '../MediumComponents/MediumContractRatesNew'

import {
    Menu, MenuItem, Switch, Button, Typography,
    Card, Stack, Table, TableRow, TableCell, Divider, MenuList, Grid,
    TextField, DialogActions, Box
} from "@suid/material";
import { getText } from '../SmallComponents/Translate'

const ShowMenu = () => {
    const [anchorEl, setAnchorEl] = createSignal<HTMLElement | null>(null);
    const [selectedMenuIndex, setSelectedMenuIndex] = createSignal<number>(1);

    const openMenu = () => !!anchorEl();

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenuListItem = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        openMenu()
    };

    const txtAllocationAndInvoicing = getText("workflowpage", "allocationandinvoicing");
    const txtWorkFixedHours = getText("workflowpage", "workfixedhours");
    const txtInvoiceFixedHours = getText("workflowpage", "invoicefixedhours");
    const txtReimburse = getText("workflowpage", "reimburse");
    const txtReimburseAll = getText("workflowpage", "reimburseall");
    const txtReimburseTransport = getText("workflowpage", "reimbursetransport");
    const txtReimburseHospitality = getText("workflowpage", "reimbursehospitality");
    const txtReimburseMeals = getText("workflowpage", "reimbursemeals");
    const txtContract = getText("workflowpage", "contract");
    const txtIncludeSignature = getText("workflowpage", "includesignature");
    const txtConfirm = getText("workflowpage", "confirm");


    const menuItems = [
        { text: txtAllocationAndInvoicing(), disabled: true, },
        { text: txtWorkFixedHours(), switch: true, checked: true, },
        { text: txtInvoiceFixedHours(), switch: true, checked: false, },
        { text: txtReimburse(), disabled: true, },
        { text: txtReimburseAll(), switch: true, checked: false, },
        { text: txtReimburseTransport(), switch: true, checked: true, },
        { text: txtReimburseHospitality(), switch: true, checked: true, },
        { text: txtReimburseMeals(), switch: true, checked: false, },
        { text: txtContract(), disabled: true, },
        { text: txtIncludeSignature(), switch: true, checked: false, },
        { text: txtConfirm(), switch: true, checked: true, },
    ];

    return (
        <>
            <Button size="small" color="primary" variant="text" onClick={handleClickMenuListItem}>  <MenuIcon /> </Button>
            {openMenu() && (

                <Menu anchorEl={anchorEl()} open={openMenu()} onClose={handleMenuClose}>
                    <MenuList >

                        {menuItems.map((item, index) => (
                            <MenuItem value={index} disabled={item.disabled}>
                                {item.switch && <Switch id="autoconfirmedEnabled" defaultChecked={item.checked} />}
                                {item.text}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

            )}
        </>

    );
}


interface CustomerWFTemplatePageProps {
    header: string;
    type: string; // "contract" | "proposal" | "interview" | "matching" | "drop" | "greylist";
}


const CustomerWFTemplatePage = (props: CustomerWFTemplatePageProps) => {
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [state, setState] = createStore({
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const [textMessage, setTextMessage] = createSignal("");

    const formatNumberBasedOnLocale = (numberString: string): string => {
        const number = Number(numberString.replace(/,/g, ''));
        const formatter = new Intl.NumberFormat(navigator.language);
        return formatter.format(number);
    }

    const txtOptions = getText("workflowpage", "options");
    const txtSendContract = getText("workflowpage", "sendcontract");
    const txtSendExtension = getText("workflowpage", "sendextension");
    const txtSendProposal = getText("workflowpage", "sendproposal");
    const txtSend = getText("workflowpage", "send");
    const txtInternalMessage = getText("workflowpage", "internalmessage");
    const txtExternalMessage = getText("workflowpage", "externalmessage");
    const txtSaveDraft = getText("workflowpage", "savedraft");

    const getButtonActionText = (type: string) => {
        if (type === "contract")
            return txtSendContract();
        else if (type === "extendContract")
            return txtSendExtension();
        else if (type === "proposal")
            return txtSendProposal();
        else
            return txtSend()
    }

    return (

        <>
            {/* ********************** */}
            {/* Show button in wf bar */}
            {/* ********************** */}

            <Card sx={{ margin: 1 }}>

                <Stack direction="column" alignItems="top" justifyContent="space-between" sx={{ display: 'flex' }} >
                    {/* Addition navigation options  */}
                    <Box sx={{ margin: 1, display: 'flex', }} >

                        <ShowMenu />
                        <ShowHeading>{getButtonActionText(props.type)} to {props.header}</ShowHeading>
                    </Box>
                </Stack>

                <Divider />

                <Card sx={{ margin: 1, display: 'flex' }}>


                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6}  >

                            <CardWithMinimize header={txtInternalMessage() as string} type="list" >

                                <TextField
                                    required
                                    fullWidth={true}

                                    id="textarea-input"


                                    multiline
                                    rows={6}

                                    autoComplete="text"
                                    variant="outlined"
                                    size="small"
                                />
                            </CardWithMinimize>


                            <CardWithMinimize header={txtOptions() as string} type="list" >
                                <Stack margin={1}>
                                    {/* Contract Rates - both simple and advanced*/}
                                    <ContractRates
                                        calendarDays={state.calendarDays}
                                        showAllocation={true}
                                        numberOfHours={String(state.totalHours)}
                                        setHours={(newValue) => handleChange('totalHours', newValue)}
                                        numberOfOnSiteHours={state.allocationOnSite}
                                        numberOfRemoteHours={state.allocationRemote}
                                        setOnSiteAllocation={(newValue) => handleChange('allocationOnSite', newValue)}
                                        setRemoteAllocation={(newValue) => handleChange('allocationRemote', newValue)}

                                        allocation={state.allocation}
                                        setAllocation={(newValue) => handleChange('allocation', newValue)}
                                        allocationUnits={state.allocationUnits}
                                        setAllocationUnits={(newValue) => handleChange('allocationUnits', newValue)}
                                        allocationUnitsPr={state.allocationUnitsPr}
                                        setAllocationUnitsPr={(newValue) => handleChange('allocationUnitsPr', newValue)}

                                        rateModel={state.rateModel}
                                        setRateModel={(newValue) => handleChange('rateModel', newValue)}
                                        expenseModel={state.expenseModel}
                                        setExpenseModel={(newValue) => handleChange('expenseModel', newValue)}

                                        currency={state.currency}
                                        setCurrency={(newValue) => handleChange('currency', newValue)}

                                        ratePer={state.ratePer}
                                        setRatePer={(newValue) => handleChange('ratePer', newValue)}

                                        rateValue={state.rateValue.toString()}
                                        remoteRateValue={state.remoteRateValue.toString()}
                                        onSiteRateValue={state.onSiteRateValue.toString()}
                                        setRateValue={(newValue) => handleChange('rateValue', newValue)}
                                        setRemoteRateValue={(newValue) => handleChange('remoteRateValue', newValue)}
                                        setOnSiteRateValue={(newValue) => handleChange('onSiteRateValue', newValue)}

                                        expenseValue={state.expenseValue.toString()}
                                        remoteExpenseValue={state.remoteExpenseValue.toString()}
                                        onSiteExpenseValue={state.onSiteExpenseValue.toString()}
                                        setExpenseValue={(newValue) => handleChange('expenseValue', newValue)}
                                        setRemoteExpenseValue={(newValue) => handleChange('remoteExpenseValue', newValue)}
                                        setOnSiteExpenseValue={(newValue) => handleChange('onSiteExpenseValue', newValue)}

                                        totalHours={state.totalHours}
                                        totalRemoteHours={state.totalRemoteHours}
                                        totalOnSiteHours={state.totalOnSiteHours}

                                        sumRates={state.sumRates}
                                        sumExpenses={state.sumExpenses}
                                        sumTotal={state.sumTotal}
                                        setSumRates={(newValue) => handleChange('sumRates', newValue)}
                                        setSumExpenses={(newValue) => handleChange('sumExpenses', newValue)}
                                        setSumTotal={(newValue) => handleChange('sumTotal', newValue)}
                                    />
                                </Stack>
                            </CardWithMinimize>
                            {/* Sum Details*/}
                            {/*  direction={{ xs: 'column', sm: 'row' }} */}
                            {/* justifyContent="space-between" */}


                        </Grid>
                        <Grid item xs={12} md={6} >

                            {/* ***************************** */}
                            {/* Preview message section       */}
                            {/* ***************************** */}

                            <Card sx={{ backgroundColor: 'background.default'[50], flexGrow: 1, margin: 1 }} >
                                <Stack direction="column" margin={1} spacing={2}>

                                    {/* Message*/}
                                    <CardWithMinimize header={txtExternalMessage() as string} type="list" >
                                        <TextField
                                            required
                                            fullWidth={true}
                                            sx={{ width: "100%" }}
                                            id="textarea-input"

                                            multiline
                                            rows={4}

                                            autoComplete="text"
                                            variant="outlined"
                                            size="small"
                                            value={textMessage()}

                                            onChange={(event) => {
                                                setTextMessage(event.target.value);

                                            }}
                                        />
                                    </CardWithMinimize>

                                    {/*  Typography color="text.secondary" innerHTML={previewTextMessage().replace(/\n/g, '<br>')} > */}

                                    <Typography color="text.secondary" p={0.5} >

                                        Hi Michael,<br />

                                        We are delighted to have you join our project. Attached to this message, you will find the contract that requires your signature. Should you have any inquiries, feel free to reach out to us.

                                        <Show
                                            when={textMessage()}
                                            fallback={
                                                <>
                                                    <br /><br />
                                                </>
                                            }
                                        >
                                            <br />
                                            {textMessage()}
                                            <br /><br />
                                        </Show>


                                        Here is a summary of the contract's key details:


                                        <Table size="small" >
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Total allocation:</TableCell>
                                                <TableCell align="right"> {state.totalHours} hours</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Total estimated rate fees:</TableCell>
                                                <TableCell align="right">{state.sumRates} {state.currency}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Total estimated expenses:</TableCell>
                                                <TableCell align="right">{state.sumExpenses} {state.currency}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Total estimated contract value:</TableCell>
                                                <TableCell align="right">{state.sumTotal} {state.currency}</TableCell>
                                            </TableRow>
                                        </Table>

                                        <br />
                                        If you require any further assistance, please do not hesitate to let us know.
                                        <br />
                                        <b>Best regards,</b><br />
                                        Lion Messia

                                    </Typography>
                                </Stack>

                            </Card>

                        </Grid>
                    </Grid>



                </Card>

                <DialogActions>

                    <Button variant="outlined" >
                        {txtSaveDraft()}
                    </Button>
                    <Button variant="contained">
                        {getButtonActionText(props.type)}
                    </Button>


                </DialogActions>
            </Card>

        </>
    );
}

export { CustomerWFTemplatePage }