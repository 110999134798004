import { createSignal, Show, For } from "solid-js";
import { createStore, } from "solid-js/store";
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { MenuIcon } from "../icons"

import { ShowHeading } from '../SmallComponents/SmallHeader'

import {
    Menu, MenuItem, Switch, Button, Typography, Grid,
    Card, Stack, Divider, MenuList, FormControlLabel,
    TextField, DialogActions, Box
} from "@suid/material";
import { getText } from '../SmallComponents/Translate'

const ShowMenu = () => {
    const [anchorEl, setAnchorEl] = createSignal<HTMLElement | null>(null);
    const [selectedMenuIndex, setSelectedMenuIndex] = createSignal<number>(1);

    const openMenu = () => !!anchorEl();

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenuListItem = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        openMenu()
    };


    const menuItems = [

        { text: "Local action menu 1", switch: true, checked: true, },

        { text: "Local action menu 2", disabled: true, },

    ];

    return (
        <>
            <Button size="small" color="primary" variant="text" onClick={handleClickMenuListItem}>  <MenuIcon /> </Button>
            {openMenu() && (

                <Menu anchorEl={anchorEl()} open={openMenu()} onClose={handleMenuClose}>
                    <MenuList >
                        <For
                            each={menuItems}
                            >{(item, index) => (

                            <MenuItem value={index()} disabled={item.disabled}>
                                {item.switch && <Switch id="autoconfirmedEnabled" defaultChecked={item.checked} />}
                                {item.text}
                            </MenuItem>
                         )}
                        </For>
                    </MenuList>
                </Menu>

            )}
        </>

    );
}


interface CustomerWFContractSignComponentProps {
    header: string;
    type: string; // "contract" | "proposal" | "interview" | "matching" | "drop" | "greylist";
}


const CustomerWFContractSignComponent = (props: CustomerWFContractSignComponentProps) => {
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [state, setState] = createStore({
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const [textMessage, setTextMessage] = createSignal("");

    const formatNumberBasedOnLocale = (numberString: string): string => {
        const number = Number(numberString.replace(/,/g, ''));
        const formatter = new Intl.NumberFormat(navigator.language);
        return formatter.format(number);
    }
    const txtTerminateContract = getText("workflowpage", "terminatecontract")
    const txtSend = getText("workflowpage", "send");
    const txtInternalMessage = getText("workflowpage", "internalmessage");
    const txtExternalMessage = getText("workflowpage", "externalmessage");
    const txtSaveDraft = getText("workflowpage", "savedraft");
    const txtSignContract = getText("workflowpage", "signcontract")
    const txtOptions = getText("workflowpage", "options");
    const txtIncludeSignature = getText("workflowpage", "includesignature");

    const getButtonActionText = (type: string) => {
        if (type === "signContract")
            return txtSignContract()
        else
            return txtSend()
    }

    return (

        <>
            {/* ********************** */}
            {/* Show button in wf bar */}
            {/* ********************** */}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <Box sx={{ margin: 2, width: 1000, bgcolor: 'background.default'[200] }}>
                    <Card>

                        <Stack direction="column" alignItems="top" justifyContent="space-between" sx={{ display: 'flex' }} >
                            {/* Addition navigation options  */}
                            <Box sx={{ margin: 1, display: 'flex', }} >

                                <ShowMenu />
                                <ShowHeading >{getButtonActionText(props.type)} with {props.header}</ShowHeading>
                            </Box>
                        </Stack>

                        <Divider />

                        <Card sx={{ margin: 1, display: 'flex' }}>

                            <Grid container spacing={2}>

                                <Grid item xs={12} md={6}  >

                                    <CardWithMinimize header={txtInternalMessage() as string} type="list" >

                                        <TextField
                                            required
                                            fullWidth={true}
                                            id="textarea-input"
                                            multiline
                                            rows={6}
                                            autoComplete="text"
                                            variant="outlined"
                                            size="small"
                                        />
                                    </CardWithMinimize>
                                    <CardWithMinimize header={txtOptions() as string} type="list" defaultOpen={true}>
                                        <FormControlLabel
                                            control={
                                                <Switch id="autoconfirmedEnabled" defaultChecked

                                                />
                                            }
                                            label={txtIncludeSignature()}
                                            sx={{ px: 2 }}
                                        />
                                    </CardWithMinimize>

                                </Grid>
                                <Grid item xs={12} md={6} >

                                    {/* ***************************** */}
                                    {/* Preview message section       */}
                                    {/* ***************************** */}

                                    <Card sx={{ backgroundColor: 'background.default'[50], flexGrow: 1, margin: 1 }} >
                                        <Stack direction="column" margin={1} spacing={2}>

                                            {/* Message*/}
                                            <CardWithMinimize header={txtExternalMessage() as string} type="list" >
                                                <TextField
                                                    required
                                                    fullWidth={true}
                                                    sx={{ width: "100%" }}
                                                    id="textarea-input"

                                                    multiline
                                                    rows={4}

                                                    autoComplete="text"
                                                    variant="outlined"
                                                    size="small"
                                                    value={textMessage()}

                                                    onChange={(event) => {
                                                        setTextMessage(event.target.value);

                                                    }}
                                                />
                                            </CardWithMinimize>


                                            {/*  Typography color="text.secondary" innerHTML={previewTextMessage().replace(/\n/g, '<br>')} > */}

                                            <Typography color="text.secondary" p={0.5} >

                                                Hi Michael,<br />

                                                We are delighted to sign the contract with you. Please find the signed contract attached to this message.
                                                We look forward to start working together with you.

                                                <Show
                                                    when={textMessage()}
                                                    fallback={
                                                        <>
                                                            <br /><br />
                                                        </>
                                                    }
                                                >
                                                    <br />
                                                    {textMessage()}
                                                    <br /><br />
                                                </Show>

                                                <br />
                                                If you have further questions, please do not hesitate to let us know.
                                                <br />
                                                <b>Best regards,</b><br />
                                                Lion Messia

                                            </Typography>
                                        </Stack>

                                    </Card>
                                </Grid>
                            </Grid>

                        </Card>

                        <DialogActions>

                            <Button variant="outlined" >
                                {txtSaveDraft()}
                            </Button>
                            <Button variant="contained">
                                {getButtonActionText(props.type)}
                            </Button>

                        </DialogActions>
                    </Card>
                </Box>
            </Box>
        </>
    );
}

export { CustomerWFContractSignComponent }